.home {
  margin: 3rem;
}

.home-img {
  width: 80%;
  max-width: 30rem;
}

.home-version-with-title {
  margin: 3rem auto;
  width: auto;
  max-width: 60rem;
}

.home-img-with-title {
  width: 100%;
}