.main {
  text-align: center;
  font: sans-serif;
  line-height: 1.5;
  color: #363889;
}

.link {
  color: #61dafb;
}

.line {
  color: #a7a9e4;
}